import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Typography, Box } from '@material-ui/core';
import styles from './styles';

const Sponsor = ({ image, title }) => {
  const classes = styles();
  return (
    <div className={classes.wrapper}>
      <GatsbyImage className={classes.sponsorImage} image={image} alt={title} />
      <Box my={2} className={classes.titleWrapper}>
        <Typography className={classes.sponsorTitle}>{title}</Typography>
      </Box>
    </div >
  )
}

export default Sponsor

Sponsor.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};
