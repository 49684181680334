import React from 'react';
import styles from './styles';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image"
import { Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";

import CustomButton from '../../CustomButton/customButton';
import { KNOW_MORE } from '../../../utils/constants'

const HomepageLink = ({ image, title, description, link }) => {
  const classes = styles();

  return (
    <Grid item sm={6} lg={3} style={{ position: 'relative' }}>
      <div className={classes.homepageLinksBackground}></div>
      <Card className={classes.root} elevation={0}>
        <CardContent classes={{ root: classes.content }}>
          <GatsbyImage image={image} className={classes.media} alt={title} loading="eager" />
          <CardContent className={classes.noHorizontalPadding}>
            <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
              {title}
            </Typography>
          </CardContent>
          <CardContent>
            <Typography className={classes.description} variant="body2" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardContent>
        <CardActions>
          <CustomButton link={link} text={KNOW_MORE} className={classes.cardButton} />
        </CardActions>
      </Card>
    </Grid>
  );
}

export default HomepageLink

HomepageLink.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string.isRequired
};