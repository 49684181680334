import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';
import { Grid, Typography, Box } from '@material-ui/core';

import Sponsor from './Sponsor';
import styles from './styles';
import { addProtocol } from '../../utils/format';

const Sponsors = ({ sponsors }) => {
  const classes = styles();

  return (
    <Grid container direction="column" alignItems="center" justify="center" className={classes.sponsorBackground}>
      <Box mb={6}>
        <Typography variant="h1">NOS PARTENAIRES</Typography>
      </Box>
      <Grid container justify="center" alignItems="center" >
        {sponsors.map((sponsor, key) => {
          const logo = getImage(sponsor.logo.gatsbyImageData);
          const title = sponsor.nom.nom;
          const sponsorLink = addProtocol(sponsor.link, "https");
          return (
            <a href={sponsorLink} className={classes.link} target="_blank" rel="noreferrer" key={key}>
              <Sponsor image={logo} title={title} />
            </a>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default Sponsors;

Sponsors.propTypes = {
  sponsors: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      resize: PropTypes.shape({
        src: PropTypes.string
      })
    }).isRequired,
    nom: PropTypes.shape({
      nom: PropTypes.string
    }).isRequired
  })).isRequired
};
