import React from 'react';
import { Link } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import IconButton from '@material-ui/core/IconButton';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './styles';
import PropTypes from 'prop-types';

const CarouselInfo = ({ articles }) => {

  const classes = styles();

  return (
    <Carousel
      autoPlay
      infiniteLoop
      renderArrowPrev={(onClickHandler, hasPrev) => hasPrev && (
        <IconButton onClick={onClickHandler} className={classes.arrowButtonLeft}>
          <ArrowBackIosRoundedIcon className={classes.arrowIcon} />
        </IconButton>
      )}
      renderArrowNext={(onClickHandler, hasNext) => hasNext && (
        <IconButton onClick={onClickHandler} className={classes.arrowButtonRight}>
          <ArrowForwardIosRoundedIcon className={classes.arrowIcon} />
        </IconButton>
      )}
      showStatus={false}
      showThumbs={false}
      renderItem={(item) => (
        <div className={classes.view}>
          {item}
        </div>
      )}
    >
      {articles.map((article) => {
        return (
          <Grid container key={article.titre} justify="center">
            <img src={article.image?.file?.url} alt={article.image?.description} />
            <Grid item container alignContent="center" alignItems="center" justify="center" className={classes.mainContainer}>
              <Link to={`/actualites/${article.slug}`} className={classes.link}>
                <Grid item zeroMinWidth container alignContent="center" alignItems="center" justify="space-around" direction="column" className={classes.titleContainer} spacing={0}>
                  <Typography component="h2" className={classes.title}>{article.titre}</Typography>
                  <Typography variant="body2" component="p">{article.description?.description}</Typography>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        )
      })}
    </Carousel>
  );
}
export default CarouselInfo

CarouselInfo.propTypes = {
  articles: PropTypes.array.isRequired

};

