import { fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2)
  },
  titleWrapper: {
    width: '80%',
  },
  sponsorTitle: {
    color: theme.palette.secondary.main,
    textTransform: 'capitalize',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  sponsorImage: {
    boxShadow: `0px 6px 6px ${fade(theme.palette.primary.main, .29)}`,
    width: '123px !important',
    height: '123px !important',
    borderRadius: '50%'
  },
}));