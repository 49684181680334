import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  relativeWrapper: {
    position: 'relative'
  },
  subtitle: {
    textAlign: "right",
    margin: '5% 5% 5% 20%'
  },
  homepageLinksBackground: {
    position: 'absolute',
    background: theme.palette.primary.mainBGColor,
    height: 200,
    top: '13%',
    width: '100%'
  },
  pubContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5%'
  },
  pubPicture: {
    width: '100%'
  },
  emptyBox: {
    height: 50
  }
}));