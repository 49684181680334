import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Box, Grid } from '@material-ui/core';

import Layout from '../components/layout';
import Sponsors from '../components/Sponsors';
import CarouselInfo from '../components/CarouselInfo/index';
import HomepageLink from '../components/Homepage/HomepageLink';

import "./reset.css";
import styles from '../pagesStyles/indexStyles'
import { addProtocol } from '../utils/format'

const IndexPage = ({ data }) => {
  const classes = styles();
  const sponsors = data.sponsors.nodes;
  const md = data.markdownRemark.frontmatter;

  const pubLink = data?.contentfulPub?.affiliationLink;
  const pubPicture = data?.contentfulPub?.imagePublicitaire?.file?.url;

  const carouselArticles = data.allContentfulArticles.nodes; 

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>{md.SEOtitle}</title>
        <meta name="description" content={md.SEOdescription} />
      </Helmet>

      <CarouselInfo 
      articles={carouselArticles}
      />
      
      <Box className={classes.emptyBox}></Box>

      {/* Links */}
      <Grid container justify="center">

        <HomepageLink
          image={md.serviceImage1.childImageSharp.gatsbyImageData}
          title={md.serviceTitle1}
          description={md.serviceDescription1}
          link="/cotations"
        />

        <HomepageLink
          image={md.serviceImage2.childImageSharp.gatsbyImageData}
          title={md.serviceTitle2}
          description={md.serviceDescription2}
          link="/tracabilite"
        />

        <HomepageLink
          image={md.serviceImage3.childImageSharp.gatsbyImageData}
          title={md.serviceTitle3}
          description={md.serviceDescription3}
          link="/formations"
        />

        <HomepageLink
          image={md.serviceImage4.childImageSharp.gatsbyImageData}
          title={md.serviceTitle4}
          description={md.serviceDescription4}
          link="/juridique"
        />

      </Grid>

      {/* Marketting banner space */}
      { pubPicture &&
        <Box className={classes.pubContainer}>
          {/* use <a> instead of Gatsby Link for external links wich have to open in another window */}
          <a href={addProtocol(pubLink, "https")} target="_blank" rel="noopener noreferrer">
            <img src={pubPicture} alt="publicité" className={classes.pubPicture} />
          </a> 
        </Box>
      }

      {/* Sponsors */}
      <Sponsors sponsors={sponsors} title={md.title2} />

    </Layout >
  )
}

export default IndexPage

export const query = graphql`
  query {
    sponsors: allContentfulPartenaires(sort: { fields: createdAt, order: ASC }) {
      nodes {
        nom {
          nom
        }
        logo {
          gatsbyImageData
        }
        link
        createdAt(formatString: "D/M/YYYY")
      }
    },
    contentfulPub {
      affiliationLink
      imagePublicitaire {
        file {
          url
        }
      }
    },
    allContentfulArticles(limit: 3, sort: {fields: createdAt, order: DESC}, filter: { adherent: {eq: false }}) {
      nodes {
        slug
        titre
        image {
          file {
            url
          }
          description
        }
        description {
          description
        }
      }
    },
    markdownRemark(fileAbsolutePath: {regex: "/homePage.md/"}) {
        frontmatter {
            SEOtitle
            SEOdescription
            serviceImage1 {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  height: 200
                  placeholder: BLURRED
                  backgroundColor: "#fafafa3a"
                )
              }
            }
            serviceTitle1
            serviceDescription1
            serviceImage2 {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  height: 200
                  placeholder: BLURRED
                  backgroundColor: "#fafafa3a"
                )
              }
            }
            serviceTitle2
            serviceDescription2
            serviceImage3 {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  height: 200
                  placeholder: BLURRED
                  backgroundColor: "#fafafa3a"
                )
              }
            }
            serviceTitle3
            serviceDescription3
            serviceImage4 {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  height: 200
                  placeholder: BLURRED
                  backgroundColor: "#fafafa3a"
                )
              }
            }
            serviceTitle4
            serviceDescription4
            title2
          }
      }
  }
`
