import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  sponsorBackground: {
    backgroundColor: theme.palette.secondary.mainBGColor,
    minHeight: 500,
    padding: '3% 0',
  },
  link: {
    textDecoration: 'none'
  }
}));
