import { fade, makeStyles } from '@material-ui/core/styles/';

export default makeStyles(theme => ({
  root: {
    maxWidth: 350,
    margin: '1rem auto',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    borderRadius: 0,
    background: 'transparent'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  media: {
    boxShadow: ` 0px 3px 6px ${fade(theme.palette.primary.main, 0.4)}`,
    background: theme.palette.secondary.main
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontSize: 'clamp(18px, 2.2vw, 23px)',
    fontWeight: 600,
    textAlign: 'center',
    height: '55px',
    color: theme.palette.secondary.main,
  },
  cardImage: {
    width: '30%',
    maxHeight: 220
  },
  cardButton: {
    width: '10%'
  },
  description: {
    color: theme.palette.primary.main,
    marginRight: '5%',
    marginLeft: '5%',
    fontStyle: 'italic',
    textAlign: 'justify'
  },
  homepageLinksBackground: {
    position: 'absolute',
    background: theme.palette.primary.mainBGColor,
    height: '25%',
    top: '23%',
    width: '100%',
    zIndex: '-1',
    '@media(min-width:450px) and (max-width: 600px)': {
      top: '20%',
      height: '25%'
    },
  },
  noHorizontalPadding: {
    padding: '1rem 0'
  }
}));
